import {getSiteInCluster} from "src/server/clusters";
import {getSite} from "src/server/preloader/site";

const fetchGeneralData = async host => {
  const siteConfig = getSiteInCluster(host);

  return {
    site: await getSite(null, host),
    shop: null,
    host: null,
    siteGroupName: siteConfig.name,
  };
};

export default fetchGeneralData;
