import * as Sentry from "@sentry/nextjs";
import {getTags} from "src/server/preloader/utils";
import {getHomeServerSideProps, getHomeStaticProps} from "src/server/preloader/homePage";

function KioskHomePage() {
  return null;
}

export async function ssr_getServerSideProps(context) {
  Sentry.setTags(getTags("getServerSideProps", context));

  return getHomeServerSideProps(context);
}

export async function isr_getStaticProps(context) {
  Sentry.setTags(getTags("getStaticProps", context));

  return getHomeStaticProps(context);
}

export default KioskHomePage;
