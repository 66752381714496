import * as DealsApi from "src/core/api/deals";

export async function getDeals(axiosInstance) {
  const requests = [await getPromotions(axiosInstance), await getRewards(axiosInstance)];
  return Promise.all(requests).then(response => {
    return {
      promotions: response[0]?.data,
      rewards: response[1]?.data,
    };
  });
}

export async function getPromotions(axiosInstance) {
  return DealsApi.promotions(
    {
      "filter[is_featured]": true,
      "page[offset]": 0,
    },
    axiosInstance
  );
}

export async function getRewards(axiosInstance) {
  return DealsApi.rewards(
    {
      "filter[is_featured]": true,
      "page[offset]": 0,
    },
    axiosInstance
  );
}
